export enum UserRole {
  SUPERADMIN = 'sadmin',
  ADMINISTRATEUR = 'administrateur',
  RESPONSABLE = 'responsable',
  CARISTE = 'cariste',
  OTHER = '',
}

export enum UserRoleForEmail {
  register_bo = 'register_bo',
  register_app = 'register_app',
}
