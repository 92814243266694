import Cookies from 'js-cookie'
import {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from 'react'
import Define from '../constants/define'
import authApi from '../http/authApi'
import { handleRefreshToken } from '../http/clientAPI'
import { Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  chooseClient,
  getClients,
  getCompanies,
  getWarehouses,
  setClientFirstLoad,
} from '../redux/reducers/accountSlice'
import { useLocation } from 'react-router-dom'
import clientWarehouseApi from '../http/clientWarehouseApi'
import { resetPermissions, setRole } from '../redux/reducers/permissionSlice'
import { showPermissionError } from '../utils/notification'
import {
  getLocalClientCompanyWarehouse,
  saveClientCompanyWarehouseToLocal,
} from '../utils/localStorage'
import { RootState } from '../app/store'
import AccountError from '../components/Common/AccountError'

const AuthGuard: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const baseURL = process.env.REACT_APP_API_ENDPOINT?.replace('/api/v1', '')
  const ssoURL = `${baseURL}/?s_url=${window.location.href}`
  const cookieXValue = Cookies.get('SSO_COOKIE-X')
  const [isHide, setIsHide] = useState<boolean>(true)
  const { active, isWMSPermission } = useSelector(
    (state: RootState) => state.permission
  )
  const {
    clientFetched,
    companyFetched,
    warehouseFetched,
    clientList,
    companyList,
    warehouseList,
  } = useSelector((state: RootState) => state.account)

  const waitCookie = async () => {
    return await new Promise((resolve, reject) => {
      let timeOut = 0
      const interval = setInterval(() => {
        timeOut += 1
        if (cookieXValue) {
          resolve('')
          clearInterval(interval)
        } else {
          // wait browser get cookie until 3 seconds
          if (timeOut === 30) {
            reject('Cookie not existed')
            clearInterval(interval)
          }
        }
      }, 100)
    })
  }

  const waitLocalStorageGetItem = () => {
    return new Promise((resolve) => {
      localStorage.getItem(Define.USERNAME)
      localStorage.getItem(Define.CODE)
      localStorage.getItem(Define.ROLE)
      localStorage.getItem(Define.USER_ID)
      resolve('')
    })
  }

  useEffect(() => {
    // Check if client list, company list and warehouse list has been fetch
    if (clientFetched && companyFetched && warehouseFetched) {
      if (!clientList || !companyList || !warehouseList) {
        // If any list is undefined (it might be because client/ company/warehouse was deleted from the user), get the initial data for client, company, warehouse
        const filters = {
          user_id: localStorage.getItem(Define.USER_ID) || '',
          user_role: localStorage.getItem(Define.ROLE) || '',
        }
        getInitialData(filters)
      } else {
        const { localClient, localCompany, localWarehouse } =
          getLocalClientCompanyWarehouse()
        if (localClient && localCompany && localWarehouse) {
          dispatch(
            chooseClient({
              client: localClient,
              company: localCompany,
              warehouse: localWarehouse,
            })
          )
        }
      }
    }
  }, [clientFetched, companyFetched, warehouseFetched])

  useEffect(() => {
    // check if access token is coming to expire to refresh
    const interval = setInterval(() => {
      const expiredAtString = Cookies.get('ACCESS_TOKEN_EXP_AT')
      if (expiredAtString) {
        const timestampExpireToken = Date.parse(expiredAtString)
        // If the token is going to expire in 10 minutes, refresh it
        if (timestampExpireToken - Date.now() < 600 * 1000) {
          handleRefreshToken()
        }
      }
      // run check every 3 minutes
    }, 180 * 1000)
    return () => clearInterval(interval)
  }, [])

  async function getMe() {
    try {
      // wait until cookie is ready
      await waitCookie()

      const res = await authApi.getMe()
      const user = res.data.entry

      localStorage.setItem(
        Define.USERNAME,
        `${user.code}-${user.pre_nom} ${user.nom}`
      )

      localStorage.setItem(Define.CODE, user.code)
      localStorage.setItem(Define.ROLE, user.role)
      localStorage.setItem(Define.MAIL, user.mail)
      if (localStorage.getItem(Define.USER_ID) !== user.id) {
        localStorage.setItem(Define.USER_ID, user.id)
        waitLocalStorageRemove()
      }
      waitLocalStorageGetItem().then(() => {
        setIsHide(false)
      })
      dispatch(setRole({ role: user.role }))
    } catch (e) {
      // if cookie is expired, redirect to login page
      window.location.replace(ssoURL)
    }
  }

  useEffect(() => {
    getMe()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dispatch = useDispatch()

  // save client, warehouse, company to global state
  function setDefaultValueForUserFilters(
    filters: {
      user_role: string
      user_id: string
    },
    localCompany?: string,
    localWarehouse?: string,
    localCompanyNom?: string,
    localWarehouseNom?: string,
    localClient?: string,
    localClientCodenom?: string
  ) {
    dispatch(
      getClients({
        ...filters,
        company_id: localCompany,
        warehouse_id: localWarehouse,
        company_code_nom: localCompanyNom,
        warehouse_code_nom: localWarehouseNom,
      })
    )
    dispatch(
      getCompanies({
        ...filters,
        client_id: localClient,
        warehouse_id: localWarehouse,
        client_code_nom: localClientCodenom,
        warehouse_code_nom: localWarehouseNom,
      })
    )
    dispatch(
      getWarehouses({
        ...filters,
        client_id: localClient,
        company_id: localCompany,
        client_code_nom: localClientCodenom,
        company_code_nom: localCompanyNom,
      })
    )
  }
  const { pathname } = useLocation()

  useEffect(() => {
    async function getData() {
      // fetch data of 3 filter lists when first time mount
      const searchParams = new URLSearchParams(window.location.search)
      const clientInUrl = searchParams.get('client')
      let warehouseInUrl = searchParams.get('warehouse')
      if (warehouseInUrl && !warehouseInUrl?.includes('ware_')) {
        warehouseInUrl = `ware_${warehouseInUrl}`
      }
      let companyInUrl = searchParams.get('company')
      const {
        localClient,
        localClientCodenom,
        localCompany,
        localWarehouse,
        localCompanyNom,
        localWarehouseNom,
      } = getLocalClientCompanyWarehouse()
      const filters = {
        user_id: localStorage.getItem(Define.USER_ID) || '',
        user_role: localStorage.getItem(Define.ROLE) || '',
      }

      if (companyInUrl) {
        // In case there is param company in url
        if (
          // If params in url is different from data saved in local storage, fetch data and set it as default
          companyInUrl !== localCompany ||
          warehouseInUrl !== localWarehouse ||
          clientInUrl !== localClient
        ) {
          const companies = await clientWarehouseApi.getFilterCompany(filters)
          const choosingCompany = companies.data.entry.find(
            (item) => item.id === companyInUrl
          )
          setDefaultValueForUserFilters(
            filters,
            companyInUrl,
            undefined,
            choosingCompany?.code_nom,
            undefined,
            undefined,
            undefined
          )
        } else {
          // If params in url are same as localstorage , then treat it as default
          setDefaultValueForUserFilters(
            filters,
            localCompany,
            localWarehouse,
            localCompanyNom,
            localWarehouseNom,
            localClient,
            localClientCodenom
          )
        }
      } else if (localClient && localCompany && localWarehouse) {
        // if there are previous search condition in cache, search by them
        setDefaultValueForUserFilters(
          filters,
          localCompany,
          localWarehouse,
          localCompanyNom,
          localWarehouseNom,
          localClient,
          localClientCodenom
        )
        dispatch(setClientFirstLoad(true))
      } else {
        // Get initial data for client, company, warehouse to get permissions
        getInitialData(filters)
      }
    }
    if (!isHide) {
      getData()
    }
  }, [pathname, isHide])

  const getInitialData = async (filters: any) => {
    try {
      let filterPayload: any = { ...filters }
      // Get the first valid set of client, company, warehouse by calling api requests dependent on each other
      const clientRes = await clientWarehouseApi.getFilterClient(filterPayload)
      const clients = clientRes.data.entry
      const client = clients[0]
      filterPayload = {
        ...filterPayload,
        client_id: client.id,
      }
      const companyRes = await clientWarehouseApi.getFilterCompany(
        filterPayload
      )
      const companies = companyRes.data.entry
      const company = companies[0]
      filterPayload = {
        ...filterPayload,
        company_id: company.id,
      }
      const warehouseRes = await clientWarehouseApi.getFilterWarehouse(
        filterPayload
      )
      const warehouses = warehouseRes.data.entry
      const warehouse = warehouses[0]
      setDefaultValueForUserFilters(
        filters,
        company.id,
        warehouse.id,
        company.code_nom,
        warehouse.code_nom,
        client.id,
        client.code_nom
      )
      saveClientCompanyWarehouseToLocal(
        client.id || '',
        company.id || '',
        warehouse.id || '',
        client.code_nom || '',
        company.code_nom || '',
        warehouse.code_nom || ''
      )
      dispatch(
        chooseClient({
          client: client.id,
          company: company.id,
          warehouse: warehouse.id,
        })
      )
    } catch (error) {
      console.log('getInitialData has an error:', error)
      showPermissionError()
      dispatch(resetPermissions())
    }
  }

  const waitLocalStorageRemove = () => {
    return new Promise((resolve) => {
      localStorage.removeItem(Define.CHOOSING_COMPANY)
      localStorage.removeItem(Define.CHOOSING_COMPANY_CODENOM)
      localStorage.removeItem(Define.CHOOSING_CLIENT)
      localStorage.removeItem(Define.CHOOSING_CLIENT_CODENOM)
      localStorage.removeItem(Define.CHOOSING_WAREHOUSE)
      localStorage.removeItem(Define.CHOOSING_WAREHOUSE_CODENOM)
      resolve('')
    })
  }

  if (!active) {
    return <AccountError type="inactiveAccount" />
  }

  if (!isWMSPermission) {
    return <AccountError type="noWMSPermission" />
  }

  if (!isHide) {
    if (!cookieXValue) {
      if (process.env.NODE_ENV.toString() === 'development') return null
      else window.location.replace(ssoURL)
    } else {
      return <>{children as ReactElement}</>
    }
  }

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <Spin size="large" />
    </div>
  )
}

export default AuthGuard
