import { GetAllUserPayload, UtilisateurArrayInterface } from '../models'
import baseAxios from './clientAPI'
import { getUrlFilterParams } from '../utils'

const utilisateurApi = {
  getAllUser(payload: GetAllUserPayload): Promise<UtilisateurArrayInterface> {
    const { condition, pageSize, pageIndex } = getUrlFilterParams()
    const url = `/user/all`
    return baseAxios.get(url, {
      params: {
        limit: payload.limit || pageSize,
        offset: payload.offset || (pageIndex - 1) * pageSize || 0,
        ...condition,
      },
    })
  },
}

export default utilisateurApi
