import moment from 'moment'
import dayjs from 'dayjs'
import { Adresses, AttCommon, LocationType, StockType } from '../models'

export const yearTimestamps_1 = 86400 * 365 * 1000
export const yearTimestamps_100 = yearTimestamps_1 * 100

export const formatTwoDigits = (num: Number): string => {
  let formattedNumber = num.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })

  return formattedNumber
}

export const isOnlyContainsNumber = (value: string): boolean =>
  /^\d+$/.test(value)
export const isOnlyContainsNumberAndDot = (value: string): boolean =>
  /^[+-]?\d+(\.\d+)?$/.test(value)
export const isOnlyContainsNumberStartByDot = (value: string): boolean =>
  /^\.[0-9]*$/.test(value)
export const isOnlyContainsAlphabet = (value: string): boolean =>
  /^[a-zA-Z]+$/.test(value)
export const isOnlyContainsNumberAndAlphabet = (value: string): boolean =>
  /^[A-Za-z0-9]*$/.test(value)
export const isContainsAtLeastSpecialCharactor = (value: string): boolean =>
  /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value)
export const isOnlyNumberFloat = (value: string): boolean =>
  /^\d*\.?\d*$/.test(value)

export const isGreatThan = (value: number, limit: number): boolean => {
  if (value <= limit) return false
  return true
}

export const isLessThan = (value: number, limit: number): boolean => {
  if (value < limit) return true
  return false
}

export const formatDecimal3 = (num: Number | string): string => {
  if (typeof num === 'string') {
    if (num === '') return ''
    if (isOnlyNumberFloat(num)) return Number(num).toFixed(3)

    return '0.000'
  }

  num = Number(num)
  return num.toFixed(3)
}

export const formatDecimal = (
  initNumber: number | string,
  numberAfterComma: number
): string => {
  if (typeof initNumber === 'string') {
    if (initNumber === '') return ''
    if (isOnlyNumberFloat(initNumber))
      return Number(initNumber).toFixed(numberAfterComma)

    return '0.00'
  }

  initNumber = Number(initNumber)
  return initNumber.toFixed(numberAfterComma)
}

export const yyyyMMddFormat = 'YYYY-MM-DD'
export const ddMMyyyyFormat = 'DD/MM/YYYY'
export const ddMMyyFormat = 'DD/MM/YY'

export const dateTo_ddmmyyyy = (date: Date) => {
  const yyyy = date.getFullYear()
  let mm: string = String(date.getMonth() + 1) // Months start at 0!
  let dd: string = String(date.getDate())

  if (Number(dd) < 10) dd = '0' + dd
  if (Number(mm) < 10) mm = '0' + mm

  const formattedToday = dd + '/' + mm + '/' + yyyy
  return formattedToday
}

export const dateTo_ddmmyy = (date: Date) => {
  const yy = date.getFullYear().toString().slice(-2)
  let mm: string = String(date.getMonth() + 1) // Months start at 0!
  let dd: string = String(date.getDate())

  if (Number(dd) < 10) dd = '0' + dd
  if (Number(mm) < 10) mm = '0' + mm

  const formattedToday = dd + '/' + mm + '/' + yy
  return formattedToday
}

export const dateTo_hhmm = (date: Date) => {
  const dateText = date.toTimeString()
  const [hh, mm] = dateText.split(' ')[0].split(':')
  return `${hh}:${mm}`
}

export const stringToDateFormat = (date: string) => {
  if (!date) return ''
  let newDate = date
    .split('')
    .filter((item) => item !== '/')
    .join('')
  const dd = newDate.substring(0, 2)
  const mm = newDate.substring(2, 4)
  const yyyy = newDate.substring(4)

  return `${dd}/${mm}/${yyyy}`
}

export const checkStringDateIsValid = (date: string) => {
  return moment(date, 'DD/MM/YYYY', true).isValid()
}

export const removeSlashFromDateString = (date: string) => {
  return date
    .split('')
    .filter((item) => item !== '/')
    .join('')
}

export const formatDateTime = (
  date: number | string,
  format = 'DD/MM/YYYY'
) => {
  return moment(Number(date) * 1000).format(format)
}

export function ddMMyyyyTo_date(stringDate: string) {
  const dateParts = stringDate.split('/')
  const [dd, mm, yyyy] = dateParts

  return new Date(Number(yyyy), Number(mm) - 1, Number(dd))
}

const sortDirections = {
  ascend: 'asc',
  descend: 'desc',
  undefined: undefined,
}

export function getUrlFilterParams() {
  const condition = Object.fromEntries(
    new URLSearchParams(window.location.search)
  )
  // in case there are sort params in url
  if (condition.sort && condition.direction) {
    // @ts-ignore
    const sortDirection = sortDirections[condition.direction]
    if (sortDirection) {
      condition.sort = `${condition.sort}|${sortDirection}`
      delete condition.direction
    } else {
      delete condition.sort
      delete condition.direction
    }
  }
  const pageSize = Number(condition['page-size']) || 25
  const pageIndex = Number(condition['page-index']) || 1
  delete condition['page-size']
  delete condition['page-index']
  delete condition['client']
  delete condition['warehouse']
  delete condition['company']

  // Support Select-Options filter fields after refresh page. Keep the selected values labeled.
  const selectOptionFields = ['types', 'object_search_types', 'actions', 'user_ids']
  selectOptionFields.forEach(field => {
    if (condition[field] && typeof condition[field] === 'string') {
      condition[field] = condition[field].split(',') as any
    }
  })

  return { condition, pageSize, pageIndex }
}

export function getCurrentFiltersText(removeParams?: string[]) {
  const condition = Object.fromEntries(
    new URLSearchParams(window.location.search)
  )
  delete condition['page-size']
  delete condition['page-index']
  delete condition['count']
  removeParams?.forEach((param) => {
    delete condition[param]
  })
  const searchParams = new URLSearchParams(condition)
  const queryString = searchParams.toString()
  return queryString.length > 0 ? `&${queryString}` : ''
}

export function isValidSecond(number: string) {
  const date = new Date(parseInt(number) * 1000)
  const timeValue = dayjs(number).valueOf()
  return (
    date.toString() !== 'Invalid Date' &&
    date.getFullYear() > 1970 &&
    timeValue > 0
  )
}

export const formatAdresses = (row: Adresses) => {
  const { addresse, code_postal, ville, pays, iso } = row
  const parts = [
    addresse,
    code_postal,
    ville,
    pays,
    iso ? `(${iso})` : null,
  ].filter((part) => part != null)
  if (parts.length === 0) {
    return '-'
  }
  return parts.join(' - ')
}

export const formatPays = (row: AttCommon) => {
  const { pays, iso } = row
  let text = ''
  if (pays) {
    text += pays
  }
  if (iso) {
    text += `-(${iso})`
  }
  if (!text) {
    text = '-'
  }
  return text
}

export const locationsToStockTransformer = (
  locations: LocationType[]
): StockType[] => {
  let stocks: StockType[]
  stocks = locations.map((item) => {
    const location = [
      item.zone_code,
      item.aisle,
      item.bay,
      item.level,
      item.position,
    ].join('-')
    return {
      id: item.id,
      available_location: location,
    } as StockType
  })
  return stocks
}
